import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

interface MetaTag {
  name?: string;
  property?: string;
  content: string;
}

interface RouteMeta {
  title: string;
  metaTags?: MetaTag[];
}

// Update RouteRecordRaw to include your custom meta type
type ExtendedRouteRecordRaw = RouteRecordRaw & {
  meta?: RouteMeta;
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    meta: {
      title: 'Cloudblast - Cheap and powerful cloud VM hosting for all your needs',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/'},
        { property: 'og:title', content: 'Cloudblast - Cheap and powerful cloud VM hosting for all your needs'},
        { property: 'og:description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.'},
        { property: 'og:image', content: ''},

        { property: 'twitter:card', content: ''},
        { property: 'twitter:url', content: 'https://cloudblast.io/'},
        { property: 'twitter:title', content: 'Cloudblast - Cheap and powerful cloud VM hosting for all your needs'},
        { property: 'twitter:description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.'},
        { property: 'twitter:imge', content: ''},
      ]
    }
  },
  {
    path: '/forex-vps',
    name: 'forexvps',
    component: () => import(/* webpackChunkName: "home" */ '../views/ForexVPS.vue'),
    meta: {
      title: 'Cloudblast - Best Forex VPS',
      metaTags: [
        { name: 'description', content: 'Experience lightning-fast trade execution and enhanced reliability with CloudBlast Forex VPS service. Optimize your trading strategy with our secure, high-speed servers, designed to keep you connected 24/7. Trust CloudBlast for seamless performance and superior uptime. Elevate your Forex trading today' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/forex-vps'},
        { property: 'og:title', content: 'Cloudblast - Best Forex VPS'},
        { property: 'og:description', content: 'Experience lightning-fast trade execution and enhanced reliability with CloudBlast Forex VPS service. Optimize your trading strategy with our secure, high-speed servers, designed to keep you connected 24/7. Trust CloudBlast for seamless performance and superior uptime. Elevate your Forex trading today'},
      ]
    }
  },
  {
    path: '/gaming-vps',
    name: 'gamingvps',
    component: () => import(/* webpackChunkName: "home" */ '../views/GamingVPS.vue'),
    meta: {
      title: 'Cloudblast - Gaming Optimized VPS',
      metaTags: [
        { name: 'description', content: 'CloudBlast Gaming VPS offers unrivaled speed and reliability for the ultimate gaming experience. Host your own gaming servers or enjoy lag-free gaming sessions with our high-performance VPS solutions tailored for gamers.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/gaming-vps'},
        { property: 'og:title', content: 'Cloudblast - Gaming Optimized VPS'},
        { property: 'og:description', content: 'CloudBlast Gaming VPS offers unrivaled speed and reliability for the ultimate gaming experience. Host your own gaming servers or enjoy lag-free gaming sessions with our high-performance VPS solutions tailored for gamers.'},
      ]
    }
  },
  {
    path: '/windows-vps',
    name: 'windowsvps',
    component: () => import(/* webpackChunkName: "home" */ '../views/WindowsVPS.vue'),
    meta: {
      title: 'Cloudblast - Windows VPS',
      metaTags: [
        { name: 'description', content: 'CloudBlast Windows VPS service delivers unmatched performance and reliability for all your Windows-based applications. Experience seamless operation 24/7 with our robust infrastructure. Elevate your projects with CloudBlast efficient and powerful Windows VPS solutions.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/windows-vps'},
        { property: 'og:title', content: 'Cloudblast - Windows VPS'},
        { property: 'og:description', content: 'CloudBlast Windows VPS service delivers unmatched performance and reliability for all your Windows-based applications. Experience seamless operation 24/7 with our robust infrastructure. Elevate your projects with CloudBlast efficient and powerful Windows VPS solutions.'},
      ]
    }
  },
  {
    path: '/high-ram-vps',
    name: 'highramvps',
    component: () => import(/* webpackChunkName: "home" */ '../views/HighRamVPS.vue'),
    meta: {
      title: 'Cloudblast - High RAM VPS',
      metaTags: [
        { name: 'description', content: 'CloudBlast High RAM VPS service delivers unparalleled performance for memory-intensive applications. Experience seamless operations for big data analytics, large-scale simulations, and resource-hungry software. Optimize your high-memory workloads with CloudBlast robust and efficient VPS solutions.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/high-ram-vps'},
        { property: 'og:title', content: 'Cloudblast - High RAM VPS'},
        { property: 'og:description', content: 'CloudBlast High RAM VPS service delivers unparalleled performance for memory-intensive applications. Experience seamless operations for big data analytics, large-scale simulations, and resource-hungry software. Optimize your high-memory workloads with CloudBlast robust and efficient VPS solutions.'},
      ]
    }
  },
  {
    path: '/ecommerce-vps',
    name: 'ecommercevps',
    component: () => import(/* webpackChunkName: "home" */ '../views/eCommerceVPS.vue'),
    meta: {
      title: 'Cloudblast - eCommerce VPS',
      metaTags: [
        { name: 'description', content: 'CloudBlast eCommerce VPS service delivers unmatched speed and stability for optimal online store performance. Ensure your shop runs smoothly 24/7, handling peak traffic with ease. Elevate your eCommerce strategy with CloudBlast robust and efficient VPS solutions' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/ecommerce-vps'},
        { property: 'og:title', content: 'Cloudblast - eCommerce VPS'},
        { property: 'og:description', content: 'CloudBlast eCommerce VPS service delivers unmatched speed and stability for optimal online store performance. Ensure your shop runs smoothly 24/7, handling peak traffic with ease. Elevate your eCommerce strategy with CloudBlast robust and efficient VPS solutions'},
      ]
    }
  },
  {
    path: '/managed-vps',
    name: 'managedvps',
    component: () => import(/* webpackChunkName: "home" */ '../views/ManagedVPS.vue'),
    meta: {
      title: 'Cloudblast - Managed VPS',
      metaTags: [
        { name: 'description', content: 'CloudBlast Managed VPS service offers unparalleled performance and expert management for your virtual servers. Our team handles all the technical complexities, allowing you to focus on your core business. Experience worry-free hosting with CloudBlast comprehensive managed solutions.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/managed-vps'},
        { property: 'og:title', content: 'Cloudblast - Managed VPS'},
        { property: 'og:description', content: 'CloudBlast Managed VPS service offers unparalleled performance and expert management for your virtual servers. Our team handles all the technical complexities, allowing you to focus on your core business. Experience worry-free hosting with CloudBlast comprehensive managed solutions.'},
      ]
    }
  },
  {
    path: '/cpanel-vps',
    name: 'cpanelvps',
    component: () => import(/* webpackChunkName: "home" */ '../views/cPanelVPS.vue'),
    meta: {
      title: 'Cloudblast - cPanel VPS',
      metaTags: [
        { name: 'description', content: 'CloudBlast cPanel-ready VPS offers the perfect blend of power and ease of use. Our VPS solutions are optimized for cPanel installation, providing you with a familiar, user-friendly interface for effortless web hosting management. Experience the flexibility of VPS with the convenience of cPanel' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/cpanel-vps'},
        { property: 'og:title', content: 'Cloudblast - cPanel VPS'},
        { property: 'og:description', content: 'CloudBlast cPanel-ready VPS offers the perfect blend of power and ease of use. Our VPS solutions are optimized for cPanel installation, providing you with a familiar, user-friendly interface for effortless web hosting management. Experience the flexibility of VPS with the convenience of cPanel'},
      ]
    }
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "home" */ '../views/HelpCenter.vue'),
    meta: {
      title: 'Cloudblast - Help',
      metaTags: [
        { name: 'description', content: 'Test About Description' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/help'},
        { property: 'og:title', content: 'Cloudblast - Help'},
        { property: 'og:description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import(/* webpackChunkName: "home" */ '../views/PricingView.vue'),
    meta: {
      title: 'Cloudblast - Pricing',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/pricing'},
        { property: 'og:title', content: 'Cloudblast - Pricing'},
        { property: 'og:description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "home" */ '../views/AboutView.vue'),
    meta: {
      title: 'Cloudblast - About',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/about'},
        { property: 'og:title', content: 'Cloudblast - About'},
        { property: 'og:description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/amsterdam',
    name: 'amsterdam',
    component: () => import(/* webpackChunkName: "home" */ '../views/LocationAmsterdam.vue'),
    meta: {
      title: 'Cloudblast - NL Amsterdam Cloud VPS',
      metaTags: [
        { name: 'description', content: 'Discover our state-of-the-art datacenter in Amsterdam AM5 Equinix facility. Offering unparalleled connectivity, security, and scalability in the heart of Europe digital hub. Explore how our Amsterdam location can power your global IT infrastructure.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/amsterdam'},
        { property: 'og:title', content: 'Cloudblast - NL Amsterdam Cloud VPS'},
        { property: 'og:description', content: 'Discover our state-of-the-art datacenter in Amsterdam AM5 Equinix facility. Offering unparalleled connectivity, security, and scalability in the heart of Europe digital hub. Explore how our Amsterdam location can power your global IT infrastructure.'},
      ]
    }
  },
  {
    path: '/compare',
    name: 'compare',
    component: () => import(/* webpackChunkName: "home" */ '../views/CompareCloudBlast.vue'),
    meta: {
      title: 'Cloudblast - Compare Us With Other Providers',
      metaTags: [
        { name: 'description', content: 'Compare CloudBlast with a variety of other VPS and Cloud Providers' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/compare'},
        { property: 'og:title', content: 'Cloudblast - Compare Us With Other Providers'},
        { property: 'og:description', content: 'Compare CloudBlast with a variety of other VPS and Cloud Providers'},
      ]
    }
  },
  {
    path: '/list-articles',
    name: 'listarticles',
    component: () => import(/* webpackChunkName: "home" */ '../views/ListArticles.vue'),
    meta: {
      title: 'Cloudblast - All CloudBlast Articles',
      metaTags: [
        { name: 'description', content: 'This is a full list of cloudblast blog articles' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/list-articles'},
        { property: 'og:title', content: 'Cloudblast - All CloudBlast Articles'},
        { property: 'og:description', content: 'This is a full list of cloudblast blog articles'},
      ]
    }
  },
  {
    path: '/hostinger-alternative',
    name: 'hostingeralternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/HostingerAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best Hostinger Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/hostinger-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best Hostinger Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to Hostinger? Secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/greencloud-alternative',
    name: 'greencloudalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/GreenCloudAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best GreenCloud Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/greencloud-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best GreenCloud Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to GreenCloud? Secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/racknerd-alternative',
    name: 'racknerdalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/RackNerdAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best RackNerd Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/racknerd-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best RackNerd Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to RackNerd? Secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/extravm-alternative',
    name: 'extravmalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/ExtraVMAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best ExtraVM Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/extravm-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best ExtraVM Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to ExtraVM? Secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/hetzner-cloud-alternative',
    name: 'hetznercloudalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/HetznerCloudAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best Hetzner Cloud Alternative',
      metaTags: [
        { name: 'description', content: 'Looking for an alternatives to Hetzner Cloud? Secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/hetzner-cloud-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best Hetzner Cloud Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to Hetzner Cloud? Secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/digitalocean-alternative',
    name: 'digitaloceanlternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/DigitalOceanAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best Digital Ocean Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/digitalocean-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best Digital Ocean Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to DigitalOcean? Secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/vultr-alternative',
    name: 'vultralternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/VultrAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best Vultr Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/vultr-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best Vultr Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to Vultr? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/linode-alternative',
    name: 'linodealternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/LinodeAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best Linode Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/linode-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best Linode Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to Linode? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/buyvm-alternative',
    name: 'buyvmalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/BuyVMAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best BuyVM Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/buyvm-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best BuyVM Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to BuyVM? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/ovhcloud-alternative',
    name: 'ovhcloudalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/OVHCloudAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best OVHCloud Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/ovhcloud-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best OVH Cloud Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to OVHCloud? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/scaleway-alternative',
    name: 'scalewayalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/ScalewayAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best Scaleway Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/scaleway-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best OVH Cloud Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to Scaleway? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/cloudzy-alternative',
    name: 'cloudzyalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/CloudzyAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best Cloudzy Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/cloudzy-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best Cloudzy Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to Cloudzy? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/clouding-alternative',
    name: 'cloudingalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/CloudingAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best Clouding.io Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/clouding-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best Clouding Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to Clouding.io? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/cloudways-alternative',
    name: 'cloudwaysalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/CloudwaysAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best Cloudways Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/cloudways-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best Cloudways Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to Cloudways? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/aws-alternative',
    name: 'awsalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/AWSAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best AWS Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/aws-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best AWS Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to AWS? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/google-cloud-alternative',
    name: 'googlecloudalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/GoogleCloudAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best Google Cloud Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/google-cloud-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best Google Cloud Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to Google Cloud? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/surfercloud-alternative',
    name: 'surfercloudalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/SurferCloudAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best SurferCloud Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/surfercloud-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best SurferCloud Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to SurferCloud? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/scalahosting-alternative',
    name: 'scalahostingalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/ScalahostingAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best ScalaHosting Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/scalahosting-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best ScalaHosting Alternative'},
        { property: 'og:description', content: 'Looking for an alternatives to ScalaHosting? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing and fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/cloudcone-alternative',
    name: 'cloudconealternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/CloudConeAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best CloudCone Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/cloudcone-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best CloudCone Alternative'},
        { property: 'og:description', content: 'Looking for an alternative to CloudCone? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing, and a fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/ultahost-alternative',
    name: 'ultahostalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/UltaHostAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best UltaHost Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/ultahost-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best UltaHost Alternative'},
        { property: 'og:description', content: 'Looking for an alternative to UltaHost? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing, and a fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/kamatera-alternative',
    name: 'kamateraalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/KamateraAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best Kamatera Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/kamatera-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best Kamatera Alternative'},
        { property: 'og:description', content: 'Looking for an alternative to Kamatera? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing, and a fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/greengeeks-alternative',
    name: 'greengeeksalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/GreenGeeksAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best GreenGeeks Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/greengeeks-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best GreenGeeks Alternative'},
        { property: 'og:description', content: 'Looking for an alternative to GreenGeeks? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing, and a fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/transip-alternative',
    name: 'transipalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/TransIPAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best TransIP Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/transip-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best TransIP Alternative'},
        { property: 'og:description', content: 'Looking for an alternative to TransIP? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing, and a fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/upcloud-alternative',
    name: 'upcloudalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/UpCloudAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best UpCloud Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/upcloud-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best UpCloud Alternative'},
        { property: 'og:description', content: 'Looking for an alternative to UpCloud? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing, and a fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/ramnode-alternative',
    name: 'ramnodealternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/RamNodeAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best RamNode Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/ramnode-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best RamNode Alternative'},
        { property: 'og:description', content: 'Looking for an alternative to RamNode? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing, and a fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/hostwinds-alternative',
    name: 'hostwindsalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/HostWindsAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best HostWinds Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/hostwinds-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best HostWinds Alternative'},
        { property: 'og:description', content: 'Looking for an alternative to HostWinds? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing, and a fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/cloudsigma-alternative',
    name: 'cloudsigmaalternative',
    component: () => import(/* webpackChunkName: "home" */ '../views/CloudSigmaAlternative.vue'),
    meta: {
      title: 'Cloudblast Is Your Best CloudSigma Alternative',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/cloudsigma-alternative'},
        { property: 'og:title', content: 'Cloudblast Is Your Best CloudSigma Alternative'},
        { property: 'og:description', content: 'Looking for an alternative to CloudSigma? CloudBlast offers secure and scalable hosting, featuring built-in DDoS protection, hourly billing, and a fast network to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },



  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "home" */ '../views/ContactView.vue'),
    meta: {
      title: 'Cloudblast - Contact',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/contact'},
        { property: 'og:title', content: 'Cloudblast - Contact'},
        { property: 'og:description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.'},
      ]
    }
  },
  {
    path: '/blog/:page?',
    name: 'blog',
    component: () => import(/* webpackChunkName: "home" */ '../views/BlogView.vue'),
    meta: {
      title: 'Cloudblast - Blog',
      metaTags: [
        { name: 'description', content: 'Stay informed with cloudblast blog. Get the latest updates, expert tutorials, and industry insights to optimize your web presence and maximize your hosting experience.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/blog'},
        { property: 'og:title', content: 'Cloudblast - Blog'},
        { property: 'og:description', content: 'Stay informed with cloudblast blog. Get the latest updates, expert tutorials, and industry insights to optimize your web presence and maximize your hosting experience.'},
      ]
    }
  },
  {
    path: '/blog/category/:category?/:page?',
    name: 'CategoryBlog',
    component: () => import(/* webpackChunkName: "home" */ '../views/CategoryBlogView.vue'),
    meta: {
      title: 'Cloudblast - Blog',
      metaTags: [
        { name: 'description', content: 'Stay informed with cloudblast blog. Get the latest updates, expert tutorials, and industry insights to optimize your web presence and maximize your hosting experience.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/blog'},
        { property: 'og:title', content: 'Cloudblast - Blog'},
        { property: 'og:description', content: 'Stay informed with cloudblast blog. Get the latest updates, expert tutorials, and industry insights to optimize your web presence and maximize your hosting experience.'},
      ]
    }
  },
  {
    path: '/article/:slug',
    name: 'article',
    component: () => import(/* webpackChunkName: "home" */ '../views/BlogSingle.vue'),
    meta: {
      title: 'Cloudblast',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' }
      ]
    }
  },
  {
    path: '/tools',
    name: 'tools',
    component: () => import(/* webpackChunkName: "home" */ '../views/Tools.vue'),
    meta: {
      title: 'Cloudblast - Tools',
      metaTags: [
        { name: 'description', content: 'Explore our comprehensive suite of Tools designed to enhance your online experience. From generating unique hostnames to creating strong passwords, our tools are easy to use and highly effective. Access all our powerful tools in one place and streamline your workflow effortlessly.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/tools'},
        { property: 'og:title', content: 'Cloudblast - Tools'},
        { property: 'og:description', content: 'Explore our comprehensive suite of Tools designed to enhance your online experience. From generating unique hostnames to creating strong passwords, our tools are easy to use and highly effective. Access all our powerful tools in one place and streamline your workflow effortlessly.'},
      ]
    }
  },
  {
    path: '/password-generator',
    name: 'passwordgenerator',
    component: () => import(/* webpackChunkName: "home" */ '../views/PasswordGenerator.vue'),
    meta: {
      title: 'Cloudblast - Password Generator',
      metaTags: [
        { name: 'description', content: 'Generate strong and secure passwords instantly with our Password Generator. Customize length and complexity to protect your online accounts with ease.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/password-generator'},
        { property: 'og:title', content: 'Cloudblast - Password Generator'},
        { property: 'og:description', content: 'Generate strong and secure passwords instantly with our Password Generator. Customize length and complexity to protect your online accounts with ease.'},
      ]
    }
  },
  {
    path: '/hostname-generator',
    name: 'hostnamegenerator',
    component: () => import(/* webpackChunkName: "home" */ '../views/HostnameGenerator.vue'),
    meta: {
      title: 'Cloudblast - Hostname Generator',
      metaTags: [
        { name: 'description', content: 'Create unique and memorable hostnames for your servers and domains with our Hostname Generator. Perfect for businesses and personal projects, it offers endless options quickly and easily.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/hostname-generator'},
        { property: 'og:title', content: 'Cloudblast - Hostname Generator'},
        { property: 'og:description', content: 'Create unique and memorable hostnames for your servers and domains with our Hostname Generator. Perfect for businesses and personal projects, it offers endless options quickly and easily.'},
      ]
    }
  },
  {
    path: '/mbit-to-tb-converter',
    name: 'mbit-to-tb-converter',
    component: () => import(/* webpackChunkName: "home" */ '../views/Mbit2TB.vue'),
    meta: {
      title: 'Cloudblast - Mbit 95th Percentile To TB Per Month Converter',
      metaTags: [
        { name: 'description', content: 'Generate strong and secure passwords instantly with our Password Generator. Customize length and complexity to protect your online accounts with ease.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/mbit-to-tb-converter'},
        { property: 'og:title', content: 'Cloudblast - Mbit 95th Percentile To TB Per Month Converter'},
        { property: 'og:description', content: 'Generate strong and secure passwords instantly with our Password Generator. Customize length and complexity to protect your online accounts with ease.'},
      ]
    }
  },
  {
    path: '/uptime-calculator',
    name: 'uptime-calculator',
    component: () => import(/* webpackChunkName: "home" */ '../views/UptimeCalculator.vue'),
    meta: {
      title: 'Cloudblast - Uptime Calculator',
      metaTags: [
        { name: 'description', content: 'Quickly convert minutes of downtime to monthly uptime percentage with our Uptime Calculator.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/uptime-calculator'},
        { property: 'og:title', content: 'Cloudblast - Uptime Calculator'},
        { property: 'og:description', content: 'Quickly convert minutes of downtime to monthly uptime percentage with our Uptime Calculator.'},
      ]
    }
  },
  {
    path: '/cidr-calculator',
    name: 'cidr-calculator',
    component: () => import(/* webpackChunkName: "home" */ '../views/CIDRCalculator.vue'),
    meta: {
      title: 'Cloudblast - IP CIDR Calculator',
      metaTags: [
        { name: 'description', content: 'Quickly convert minutes of downtime to monthly uptime percentage with our Uptime Calculator.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/cidr-calculator'},
        { property: 'og:title', content: 'Cloudblast - IP CIDR Calculator'},
        { property: 'og:description', content: 'Quickly convert minutes of downtime to monthly uptime percentage with our Uptime Calculator.'},
      ]
    }
  },
  {
    path: '/qr-code-generator',
    name: 'qrcode-generator',
    component: () => import(/* webpackChunkName: "home" */ '../views/QRCodeGenerator.vue'),
    meta: {
      title: 'Cloudblast - QR Code Generator',
      metaTags: [
        { name: 'description', content: 'Easily create QR codes from any text with our QR Code Generator. Simply input your string, and generate a QR code instantly for easy sharing and scanning.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/qr-code-generator'},
        { property: 'og:title', content: 'Cloudblast - QR Code Generator'},
        { property: 'og:description', content: 'Easily create QR codes from any text with our QR Code Generator. Simply input your string, and generate a QR code instantly for easy sharing and scanning.'},
      ]
    }
  },
  {
    path: '/barcode-generator',
    name: 'barcode-generator',
    component: () => import(/* webpackChunkName: "home" */ '../views/BarcodeGenerator.vue'),
    meta: {
      title: 'Cloudblast - BarCode Generator',
      metaTags: [
        { name: 'description', content: 'Easily create Barcodes from any text with our BarCode Generator. Simply input your string, and generate a Barcode instantly for easy sharing and scanning.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/barcode-generator'},
        { property: 'og:title', content: 'Cloudblast - BarCode Code Generator'},
        { property: 'og:description', content: 'Easily create Barcodes from any text with our BarCode Generator. Simply input your string, and generate a Barcode instantly for easy sharing and scanning.'},
      ]
    }
  },
  {
    path: '/svg-optimizer',
    name: 'svg-optimizer',
    component: () => import(/* webpackChunkName: "svg-optimizer" */ '../views/SVGOptimizer.vue'),
    meta: {
      title: 'Cloudblast - SVG Optimizer',
      metaTags: [
        { name: 'description', content: 'Optimize your SVG files effortlessly with our SVG Optimizer. Reduce file sizes, improve load times, and enhance performance by optimizing your SVG graphics.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/svg-optimizer'},
        { property: 'og:title', content: 'Cloudblast - SVG Optimizer'},
        { property: 'og:description', content: 'Optimize your SVG files effortlessly with our SVG Optimizer. Reduce file sizes, improve load times, and enhance performance by optimizing your SVG graphics.'},
      ]
    }
  },
  {
    path: '/uuid-generator',
    name: 'uuid-generator',
    component: () => import(/* webpackChunkName: "svg-optimizer" */ '../views/UUIDGenerator.vue'),
    meta: {
      title: 'Cloudblast - SVG Optimizer',
      metaTags: [
        { name: 'description', content: 'Optimize your SVG files effortlessly with our SVG Optimizer. Reduce file sizes, improve load times, and enhance performance by optimizing your SVG graphics.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/svg-optimizer'},
        { property: 'og:title', content: 'Cloudblast - SVG Optimizer'},
        { property: 'og:description', content: 'Optimize your SVG files effortlessly with our SVG Optimizer. Reduce file sizes, improve load times, and enhance performance by optimizing your SVG graphics.'},
      ]
    }
  },
  {
    path: '/javascript-minifier',
    name: 'javascript-minifier',
    component: () => import(/* webpackChunkName: "home" */ '../views/JavascriptMinifier.vue'),
    meta: {
      title: 'Cloudblast - Javascript Minifier',
      metaTags: [
        { name: 'description', content: 'Optimize your code effortlessly with our JavaScript Minifier. Reduce file sizes, improve load times, and enhance performance by minifying your JavaScript.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/javascript-minifier'},
        { property: 'og:title', content: 'Cloudblast - Javascript Minifier'},
        { property: 'og:description', content: 'Optimize your code effortlessly with our JavaScript Minifier. Reduce file sizes, improve load times, and enhance performance by minifying your JavaScript.'},
      ]
    }
  },
  {
    path: '/lorem-ipsum-generator',
    name: 'lorem-ipsum-generator',
    component: () => import(/* webpackChunkName: "home" */ '../views/LoremIpsumGenerator.vue'),
    meta: {
      title: 'Cloudblast - Lorem Ipsum Generator',
      metaTags: [
        { name: 'description', content: 'Generate placeholder text quickly with our Lorem Ipsum Generator. Create custom-length dummy text for your design mockups and prototypes. Ideal for designers and developers needing filler text for their projects.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/lorem-ipsum-generator'},
        { property: 'og:title', content: 'Cloudblast - Lorem Ipsum Generator'},
        { property: 'og:description', content: 'Generate placeholder text quickly with our Lorem Ipsum Generator. Create custom-length dummy text for your design mockups and prototypes. Ideal for designers and developers needing filler text for their projects.'},
      ]
    }
  },
  // {
  //   path: '/markdown-to-html',
  //   name: 'markdown-to-html',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/Markdown2HTML.vue'),
  //   meta: {
  //     title: 'Cloudblast - Markdown to HTML',
  //     metaTags: [
  //       { name: 'description', content: 'Easily convert your Markdown text to HTML with our Markdown to HTML Converter. Simplify your workflow by transforming Markdown documents into clean, readable HTML code instantly. Perfect for developers and content creators.' },
  //       { property: 'og:type', content: 'website'},
  //       { property: 'og:url', content: 'https://cloudblast.io/markdown-to-html'},
  //       { property: 'og:title', content: 'Cloudblast - Markdown to HTML'},
  //       { property: 'og:description', content: 'Easily convert your Markdown text to HTML with our Markdown to HTML Converter. Simplify your workflow by transforming Markdown documents into clean, readable HTML code instantly. Perfect for developers and content creators.'},
  //     ]
  //   }
  // },
  {
    path: '/base64-encorder-decoder',
    name: 'markdown-to-html',
    component: () => import(/* webpackChunkName: "home" */ '../views/Base64EncDec.vue'),
    meta: {
      title: 'Cloudblast - Base64 Encoder & Decoder',
      metaTags: [
        { name: 'description', content: 'Effortlessly encode and decode Base64 with our Base64 Encoder & Decoder. Convert your data to and from Base64 format quickly and securely. Ideal for developers working with data encoding and decoding tasks.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/base64-encorder-decoder'},
        { property: 'og:title', content: 'Cloudblast - Base64 Encoder & Decoder'},
        { property: 'og:description', content: 'Effortlessly encode and decode Base64 with our Base64 Encoder & Decoder. Convert your data to and from Base64 format quickly and securely. Ideal for developers working with data encoding and decoding tasks.'},
      ]
    }
  },
  {
    path: '/javascript-obfuscator',
    name: 'javascript-obfuscator',
    component: () => import(/* webpackChunkName: "home" */ '../views/JSObfuscator.vue'),
    meta: {
      title: 'Cloudblast - Javascript Obfuscator',
      metaTags: [
        { name: 'description', content: 'Protect your code with the JavaScript Obfuscator. Obfuscate your JavaScript to make it difficult to read and reverse-engineer, enhancing security and protecting your intellectual property. Perfect for developers looking to secure their code.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/base64-encorder-decoder'},
        { property: 'og:title', content: 'Cloudblast - Javascript Obfuscator'},
        { property: 'og:description', content: 'Protect your code with the JavaScript Obfuscator. Obfuscate your JavaScript to make it difficult to read and reverse-engineer, enhancing security and protecting your intellectual property. Perfect for developers looking to secure their code.'},
      ]
    }
  },
  {
    path: '/colors-code-converter',
    name: 'colors-code-converter',
    component: () => import(/* webpackChunkName: "home" */ '../views/ColorsConverter.vue'),
    meta: {
      title: 'Cloudblast - HEX, RGB, HSL Color Converter',
      metaTags: [
        { name: 'description', content: 'Convert between HEX, RGB, and HSL color formats with our HEX, RGB, HSL Color Converter. Get accurate color conversions instantly for your design and development needs. Perfect for designers and developers working with color schemes.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/colors-code-converter'},
        { property: 'og:title', content: 'Cloudblast - HEX, RGB, HSL Color Converter'},
        { property: 'og:description', content: 'Convert between HEX, RGB, and HSL color formats with our HEX, RGB, HSL Color Converter. Get accurate color conversions instantly for your design and development needs. Perfect for designers and developers working with color schemes.'},
      ]
    }
  },
  {
    path: '/json-validator',
    name: 'json-validator',
    component: () => import(/* webpackChunkName: "home" */ '../views/JSONValidator.vue'),
    meta: {
      title: 'Cloudblast - Json Validator & Formatter',
      metaTags: [
        { name: 'description', content: 'Ensure the accuracy and integrity of your JSON data with our JSON Validator. Quickly validate and format your JSON to catch errors and improve readability. Perfect for developers working with JSON data structures.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/json-validator'},
        { property: 'og:title', content: 'Cloudblast - Json Validator & Formatter'},
        { property: 'og:description', content: 'Ensure the accuracy and integrity of your JSON data with our JSON Validator. Quickly validate and format your JSON to catch errors and improve readability. Perfect for developers working with JSON data structures.'},
      ]
    }
  },
  {
    path: '/regex-tester',
    name: 'regex-tester',
    component: () => import(/* webpackChunkName: "home" */ '../views/RegexTester.vue'),
    meta: {
      title: 'Cloudblast - Regex Tester',
      metaTags: [
        { name: 'description', content: 'Test and debug your regular expressions with our Regex Tester. Instantly validate and refine your regex patterns for accurate matching. Perfect for developers and data analysts working with text patterns.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/regex-tester'},
        { property: 'og:title', content: 'Cloudblast - Regex Tester'},
        { property: 'og:description', content: 'Test and debug your regular expressions with our Regex Tester. Instantly validate and refine your regex patterns for accurate matching. Perfect for developers and data analysts working with text patterns.'},
      ]
    }
  },
  {
    path: '/backup-size-calculator',
    name: 'backup-size-calculator',
    component: () => import(/* webpackChunkName: "home" */ '../views/BackupSizeCalculator.vue'),
    meta: {
      title: 'Cloudblast - Backup Space Required Calculator',
      metaTags: [
        { name: 'description', content: 'Quickly estimate the required backup storage space based on your website size and backup frequency.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/backup-size-calculator'},
        { property: 'og:title', content: 'Cloudblast - Backup Space Required Calculator'},
        { property: 'og:description', content: 'Quickly estimate the required backup storage space based on your website size and backup frequency.'},
      ]
    }
  },
  {
    path: '/server-resources-estimator',
    name: 'server-resources-estimator',
    component: () => import(/* webpackChunkName: "home" */ '../views/ResourcesEstimator.vue'),
    meta: {
      title: 'Cloudblast - Server Resources Needed Calculator / Estimator',
      metaTags: [
        { name: 'description', content: 'Estimate the server resources you need based on your website type, expected traffic, and features. Find out how many Cores, RAM and SSD Space you need for your website.' },
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: 'https://cloudblast.io/server-resources-estimator'},
        { property: 'og:title', content: 'Cloudblast - Server Resources Needed Calculator / Estimator'},
        { property: 'og:description', content: 'Estimate the server resources you need based on your website type, expected traffic, and features. Find out how many Cores, RAM and SSD Space you need for your website.'},
      ]
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "home" */ '../views/TermsView.vue'),
    meta: {
      title: 'Cloudblast - Terms',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' }
      ]
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "home" */ '../views/PrivacyView.vue'),
    meta: {
      title: 'Cloudblast - Privacy',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' }
      ]
    }
  },
  {
    path: '/api',
    name: 'api',
    component: () => import(/* webpackChunkName: "home" */ '../views/ApiView.vue'),
    meta: {
      title: 'Cloudblast - API',
      metaTags: [
        { name: 'description', content: 'Secure and scalable virtual machine hosting, featuring built-in DDoS protection to ensure continuous, safe operation for your critical applications.' }
      ]
    }
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})


function isRouteMeta(object: any): object is RouteMeta {
  return typeof object === 'object' && object !== null && 'title' in object && typeof object.title === 'string';
}

router.beforeEach((to, from, next) => {
  if (to.meta && isRouteMeta(to.meta)) {
    const meta = to.meta; // Safely typed as RouteMeta due to the type guard
    document.title = meta.title;

    // Clear existing meta tags
    document.querySelectorAll('[data-vue-router-controlled]').forEach(el => el.remove());

    // Add new meta tags
    meta.metaTags?.forEach(tag => {
      const metaElement = document.createElement('meta');
      if (tag.name) {
        metaElement.setAttribute('name', tag.name);
      } else if (tag.property) { // Ensure that tag.property is checked after the interface is updated
        metaElement.setAttribute('property', tag.property);
      }
      metaElement.setAttribute('content', tag.content);
      metaElement.setAttribute('data-vue-router-controlled', '');
      document.getElementsByTagName('head')[0].appendChild(metaElement);
    });
  }

  next();
});

export default router

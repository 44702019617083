import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import AOS from 'aos'
import 'aos/dist/aos.css'

import "bootstrap/dist/js/bootstrap.js"
import 'bootstrap/dist/css/bootstrap.css'
import './assets/scss/main.scss'

import { VueReCaptcha } from 'vue-recaptcha-v3'

// createApp(App).use(VueReCaptcha, { siteKey: '6LfGYy0pAAAAAIIVtakcD_o202fd6umuijYfDmjM' }).use(router).mount('#app')
createApp(App).use(router).use(VueReCaptcha, {
    siteKey: '6LfGYy0pAAAAAIIVtakcD_o202fd6umuijYfDmjM',
    loaderOptions: {
        useRecaptchaNet: true
    }
}).mount('#app')
